import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { BookingWidget } from '../../models';
import { WidgetFont } from '../../models/responses/bol-widgets-fonts';
import { WidgetTemplate } from '../../models/responses/bol-widgets-template';

export const featureAdapter: EntityAdapter<BookingWidget> = createEntityAdapter<
  BookingWidget
>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<BookingWidget> {
  isLoading?: boolean;
  error?: any;
  templates?: WidgetTemplate[];
  fonts?: WidgetFont[];
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  templates: [],
  fonts: [],
});
