import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { BookingWidgetsStoreEffects } from './effects';
import { bookingWidgetsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('bookingWidgets', bookingWidgetsReducer),
    EffectsModule.forFeature([BookingWidgetsStoreEffects]),
  ],
})
export class BookingWidgetsStoreModule {}
