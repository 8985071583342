import { createFeatureSelector, createSelector } from '@ngrx/store';

import { BookingWidget } from '../../models';
import { WidgetFont } from '../../models/responses/bol-widgets-fonts';
import { WidgetTemplate } from '../../models/responses/bol-widgets-template';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const selectState = createFeatureSelector<State>('bookingWidgets');

export const selectAllItems = featureAdapter.getSelectors(selectState)
  .selectAll;

export const selectById = (id: number) =>
  createSelector(
    selectAllItems,
    (items: BookingWidget[]) => items && items.find((p) => +p.id === +id),
  );

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectTemplates = createSelector(
  selectState,
  (state: State): WidgetTemplate[] => state.templates,
);
export const selectFonts = createSelector(
  selectState,
  (state: State): WidgetFont[] => state.fonts,
);
