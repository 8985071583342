import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { WidgetFont } from '../../models/responses/bol-widgets-fonts';
import { WidgetTemplate } from '../../models/responses/bol-widgets-template';
import { BookingWidgetsService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';
import { BookingWidgetsLinksStoreActions } from '../booking-widgets-links-store';

import * as fromActions from './actions';

@Injectable()
export class BookingWidgetsStoreEffects {
  constructor(
    private dataService: BookingWidgetsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ properties }) =>
        this.dataService.load(properties).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSuccess({
              items: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadByIdRequest),
      switchMap(({ itemId }) =>
        this.dataService.loadById(itemId).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadByIdSuccess({
              item: response.data[0],
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadByIdFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ itemId, properties }) =>
        this.dataService.delete(itemId).pipe(
          mergeMap(() => {
            this.notifications.deleteSuccess('booking_widget');
            return [
              fromActions.deleteSuccess({
                itemId,
              }),
              BookingWidgetsLinksStoreActions.loadRequest({ properties }),
            ];
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ request, properties }) =>
        this.dataService.create(request).pipe(
          mergeMap((response: IResponseSuccess) => {
            this.notifications.createSuccess('booking_widget');
            this.router.navigate([
              '/configuration/booking-widgets-configurator/booking-widgets',
            ]);
            return [
              fromActions.createSuccess({
                item: response.data[0],
              }),
              BookingWidgetsLinksStoreActions.loadRequest({ properties }),
            ];
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ request, isAutoSave, properties }) =>
        this.dataService.update(request).pipe(
          mergeMap((response: IResponseSuccess) => {
            return [
              fromActions.updateSuccess({
                item: response.data[0],
                isAutoSave,
              }),
              BookingWidgetsLinksStoreActions.loadRequest({ properties }),
            ];
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );

  loadTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadTemplatesRequest),
      switchMap(() =>
        this.dataService.loadTemplates().pipe(
          map((templates: WidgetTemplate[]) => {
            return fromActions.loadTemplatesSuccess({
              templates,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadTemplatesFailure(error));
          }),
        ),
      ),
    ),
  );
  loadFonts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadFontsRequest),
      switchMap(() =>
        this.dataService.loadFonts().pipe(
          map((fonts: WidgetFont[]) => {
            return fromActions.loadFontsSuccess({
              fonts,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFontsFailure(error));
          }),
        ),
      ),
    ),
  );
}
