import { createAction, props } from '@ngrx/store';

import { BookingWidget } from '../../models';

export const loadRequest = createAction(
  '[Booking Widgets Links] Load Request',
  props<{
    properties: number[];
  }>(),
);

export const loadSuccess = createAction(
  '[Booking Widgets Links] Load Success',
  props<{ items: BookingWidget[] }>(),
);

export const loadFailure = createAction(
  '[Booking Widgets Links] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Booking Widgets Links] Reset State');
