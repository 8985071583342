import { createAction, props } from '@ngrx/store';

import {
  BookingWidget,
  CreateBookingWidgetRequest,
  UpdateBookingWidgetRequest,
} from '../../models';
import { WidgetFont } from '../../models/responses/bol-widgets-fonts';
import { WidgetTemplate } from '../../models/responses/bol-widgets-template';

export const loadRequest = createAction(
  '[Booking Widgets] Load Request',
  props<{
    properties: number[];
  }>(),
);

export const loadSuccess = createAction(
  '[Booking Widgets] Load Success',
  props<{ items: BookingWidget[] }>(),
);

export const loadFailure = createAction(
  '[Booking Widgets] Load Failure',
  props<{ error: any }>(),
);

export const loadByIdRequest = createAction(
  '[Booking Widgets] Load By Id Request',
  props<{ itemId: number }>(),
);

export const loadByIdSuccess = createAction(
  '[Booking Widgets] Load By Id Success',
  props<{ item: BookingWidget }>(),
);

export const loadByIdFailure = createAction(
  '[Booking Widgets] Load By Id Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Booking Widgets] Delete Request',
  props<{ itemId: number; properties: number[] }>(),
);

export const deleteSuccess = createAction(
  '[Booking Widgets] Delete Success',
  props<{ itemId: number }>(),
);

export const deleteFailure = createAction(
  '[Booking Widgets] Load Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Booking Widgets] Create Request',
  props<{ request: CreateBookingWidgetRequest; properties: number[] }>(),
);

export const createSuccess = createAction(
  '[Booking Widgets] Create Success',
  props<{ item: BookingWidget }>(),
);

export const createFailure = createAction(
  '[Booking Widgets] Create Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Booking Widgets] Update Request',
  props<{
    request: UpdateBookingWidgetRequest;
    isAutoSave?: boolean;
    properties: number[];
  }>(),
);

export const updateSuccess = createAction(
  '[Booking Widgets] Update Success',
  props<{ item: BookingWidget; isAutoSave?: boolean }>(),
);

export const updateFailure = createAction(
  '[Booking Widgets] Update Failure',
  props<{ error: any }>(),
);

export const setNumberRequest = createAction(
  '[Booking Widgets] Set Number Request',
  props<{ receiptId: number; number: number; status: 'success' | 'error' }>(),
);

export const setNumberSuccess = createAction(
  '[Booking Widgets] Set Number Success',
);

export const setNumberFailure = createAction(
  '[Booking Widgets] Set Number Failure',
  props<{ error: any }>(),
);

export const loadTemplatesRequest = createAction(
  '[Booking Widgets] Load Templates Request',
);

export const loadTemplatesSuccess = createAction(
  '[Booking Widgets] Load Templates Success',
  props<{ templates: WidgetTemplate[] }>(),
);

export const loadTemplatesFailure = createAction(
  '[Booking Widgets] Load Templates Failure',
  props<{ error: any }>(),
);

export const loadFontsRequest = createAction(
  '[Booking Widgets] Load Fonts Request',
);

export const loadFontsSuccess = createAction(
  '[Booking Widgets] Load Fonts Success',
  props<{ fonts: WidgetFont[] }>(),
);

export const loadFontsFailure = createAction(
  '[Booking Widgets] Load Fonts Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Booking Widgets] Reset State');
